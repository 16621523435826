<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>EVALUACIONES FINALIZADAS</b></h4>
          </template>
          <div class="text-right px-4 mt-3">
            <h4 class="card-title text-center">
              <b><i class="fas fa-user px-2 text-primary"></i>AUTOEVALUACIÓN</b>
            </h4>
          </div>
          <div class="table-responsive px-4 mt-3">
            <table id="pendientesUsuario" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Rol</th>
                  <th>Nombre del ciclo</th>
                  <th>Fecha inicio</th>
                  <th>Fecha fin</th>
                  <!-- <th>Opciones</th>
                  <th>Ver reporte</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in pendientesUsuario" :key="item.id">
                  <td>{{ "Propias" }}</td>
                  <td>{{ item.cicloEvaluacion.nombre }}</td>
                  <td>{{ $moment.tz(item.cicloEvaluacion.fechaDesde, "America/Costa_Rica").format("DD-MM-YYYY HH:mm") }}</td>
                  <td>{{ $moment.tz(item.cicloEvaluacion.fechaHasta, "America/Costa_Rica").format("DD-MM-YYYY HH:mm") }}</td>
                  <!-- <td><button @click="$router.push('/helex/evaluacion/'+usuario.id+'/'+item.id)" class="btn btn-primary">Evaluar</button></td>
                  <td>{{ "ver Reporte" }}</td> -->

                  <!-- <td>
                    <span class="pointer"><i class="fas fa-solid fa-pen px-1 text-success"></i> <i class="fas fa-solid fa-trash px-1 text-danger"></i></span>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </card>
        <!-- jefe -->
        <card v-if="esJefe">
          <div class="text-right px-4 mt-3">
            <h4 class="text-center">
              <b><i class="fas fa-user pt-3 px-2 text-primary"></i>JEFE</b>
            </h4>
          </div>
          <div class="table-responsive px-4 mt-3">
            <table id="pendientesJefe" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Rol</th>
                  <th>Nombre ciclo</th>
                  <th>Nombre trabajador</th>
                  <th>Fecha inicio</th>
                  <th>Fecha fin</th>
                  <!-- <th>Opciones</th>
                  <th>Ver reporte</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in pendientesJefe" :key="item.id">
                  <td>{{ "Compañero" }}</td>
                  <!-- <td>{{ item.cicloEvaluacion.nombre }}</td> -->
                  <td>{{ item.cicloEvaluacion.nombre }}</td>
                  <td>{{ item.persona.nombres }}</td>
                  <td>{{ $moment.tz(item.cicloEvaluacion.fechaDesde, "America/Costa_Rica").format("DD-MM-YYYY HH:mm") }}</td>
                  <td>{{ $moment.tz(item.cicloEvaluacion.fechaHasta, "America/Costa_Rica").format("DD-MM-YYYY HH:mm") }}</td>
                  <!-- <td><button @click="$router.push('/helex/evaluacion/'+usuario.id+'/'+item.id)" class="btn btn-primary">Evaluar</button></td>
                  <td>{{ "ver Reporte" }}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </card>
        <!-- par -->
        <card>
          <div class="text-right px-4 mt-3">
            <h4 class="text-center">
              <b><i class="fas fa-user pt-3 px-2 text-primary"></i>COMPAÑEROS</b>
            </h4>
          </div>
          <div class="table-responsive px-4 mt-3">
            <table id="pendientesPar" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Rol</th>
                  <th>Nombre ciclo</th>
                  <th>trabajador a evaluar</th>
                  <th>Fecha inicio</th>
                  <th>Fecha fin</th>
                  <!-- <th>Opciones</th>
                  <th>Ver reporte</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in pendientesPar" :key="item.id">
                  <td>{{ "Compañero" }}</td>
                  <td>{{ item.cicloEvaluacion.nombre }}</td>
                  <td>{{ getPersonaEvaluacion(item.perPrincipal) }}</td>
                  <td>{{ $moment.tz(item.cicloEvaluacion.fechaDesde, "America/Costa_Rica").format("DD-MM-YYYY HH:mm") }}</td>
                  <td>{{ $moment.tz(item.cicloEvaluacion.fechaHasta, "America/Costa_Rica").format("DD-MM-YYYY HH:mm") }}</td>
                  <!-- <td><button @click="$router.push('/helex/evaluacion/'+usuario.id+'/'+item.id)" class="btn btn-primary">Evaluar</button></td>
                  <td>{{ "ver Reporte" }}</td> -->

                  <!-- <td>
                    <span class="pointer"><i class="fas fa-solid fa-pen px-1 text-success"></i> <i class="fas fa-solid fa-trash px-1 text-danger"></i></span>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit";
export default {
  name: "DataTable",
  data() {
    return {
      usuario: {},
      pendientesUsuario: [],
      pendientesJefe: [],
      pendientesPar: [],
      personas: [],
      esJefe: false,
    };
  },
  async mounted() {
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getActividad();
    console.log;
    await this.getPersonas();
    await this.getData();
    core.index();
    window.$("#pendientesUsuario").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    window.$("#pendientesJefe").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    window.$("#pendientesPar").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
  },
  methods: {
    getPersonaEvaluacion(id) {
      let data = this.personas.find((x) => x.id == id);
      return data.nombres + " " + data.apellidos;
    },
    async getActividad() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Actividad/EsJefe/" + this.usuario.id,
        });
        this.esJefe = res;
      } catch (error) {
        this.esJefe = false;
        console.log("err", error);
      }
    },
    async getPersonas() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/ListPersona/" + this.usuario.empresa.id,
        });
        if (res.length > 0) {
          this.personas = res;
        } else {
          this.personas = [];
        }
      } catch (error) {
        this.personas = [];
        console.log("err", error);
      }
    },
    async getData() {
      this.$isLoading(true);
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "CicloEvaluacionUsuario/ListCicloEvaluacionByUsuario/" + this.usuario.id,
        });
        console.log("-************ ", res);
        if (res.length > 0) {
          this.pendientesUsuario = res.filter((x) => x.estado != 1);
        } else {
          this.pendientesUsuario = [];
        }

        let resJefe = await this.$store.dispatch("hl_get", {
          path: "CicloEvaluacionUsuario/ListByJefe/" + this.usuario.id,
        });
        if (resJefe.length > 0) {
          this.pendientesJefe = resJefe.filter((y) => y.estado != 1);
          console.log("esjefe-", this.pendientesJefe);
        } else {
          this.pendientesJefe = [];
        }

        let resPar = await this.$store.dispatch("hl_get", {
          path: "CicloEvaluacionUsuario/ListByPar/" + this.usuario.id,
        });
        console.log("par--->", resPar);
        if (resPar.length > 0) {
          this.pendientesPar = resPar.filter((z) => z.estado != 1);
        } else {
          this.pendientesPar = [];
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        this.pendientesUsuario = [];
        console.log("err", error);
      }
    },
  },
};
</script>
